import { useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { i18n } from 'i18n';

import Heading1 from 'features/report/shared/components/heading1';
import Section from 'features/report/shared/components/section';
import ChartTable from '../../shared/components/chartTable';
import { Colors } from '../../shared/constants/colors.js';
import sessionSelectors from '../../../shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import Paragraph from 'features/report/shared/components/paragraph';
import { formatNumber } from 'features/shared/utils/number';
import AdvisorNotesSection from './advisorNotesSection.js';
import { ProductAttributeType } from 'features/report/shared/services/reportStore';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

const useStyles = createUseStyles({
  headerCell: {
    overflowWrap: 'break-word',
    fontSize: '12px',
    padding: '11px 13px',
    color: Colors.gray400,
    backgroundColor: Colors.gray500,

    '&:first-of-type': {
      paddingLeft: '13px',
      paddingRight: 0
    },

    '&:last-of-type': {
      paddingRight: '13px',
      paddingLeft: 0
    }
  },
  cell: {
    overflowWrap: 'break-word',
    maxWidth: '150px',
    fontSize: '12px'
  },
  advisorNotes: {
    marginTop: 32
  },
  center: {
    textAlign: 'center'
  }
});

const OrderExecutionSummarySection = ({
  orderSummary,
  productAttributes,
  advisorNotes = ''
}) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  if (isNil(orderSummary) || orderSummary.length === 0) {
    return null;
  }

  const tableElements = [
    ...orderSummary.map(item => {
      const itemProductAttributes = productAttributes.reduce(
        (prev, { name, type }) => {
          if (type === ProductAttributeType.text) {
            return { ...prev, [name]: item.productAttributeText[name] };
          }

          if (type === ProductAttributeType.binary) {
            return item.productAttributeBinary[name]
              ? {
                  ...prev,
                  [name]: (
                    <div className={classes.center}>
                      <CheckIcon className={classes.checkIcon} />
                    </div>
                  )
                }
              : {
                  ...prev,
                  [name]: (
                    <div className={classes.center}>
                      <CrossIcon className={classes.crossIcon} />
                    </div>
                  )
                };
          }

          return null;
        },
        {}
      );

      return {
        ...item,
        ...itemProductAttributes,
        initialDeposit: formatNumber(cultureCode, item.initialDeposit),
        monthlySaving: formatNumber(cultureCode, item.monthlySaving)
      };
    }),
    {
      fund: i18n('report.orderExecutionSummary.sum', translationsConfig),
      initialDeposit: formatNumber(
        cultureCode,
        orderSummary.reduce((acc, curr) => acc + (curr.initialDeposit ?? 0), 0)
      ),
      monthlySaving: formatNumber(
        cultureCode,
        orderSummary.reduce((acc, curr) => acc + (curr.monthlySaving ?? 0), 0)
      )
    }
  ];

  return (
    <Section>
      <Heading1>
        {i18n('report.orderExecutionSummary.header', translationsConfig)}
      </Heading1>
      <Paragraph>
        {i18n('report.orderExecutionSummary.text', translationsConfig)}
      </Paragraph>
      <div>
        <ChartTable
          customClasses={{
            headerCell: classes.headerCell,
            cell: classes.cell
          }}
          headerTitles={[
            i18n('report.orderExecutionSummary.fund', translationsConfig),
            i18n('report.orderExecutionSummary.ISIN', translationsConfig),
            ...productAttributes.map(({ label, type }) => {
              if (type === ProductAttributeType.text) {
                return label;
              }

              if (type === ProductAttributeType.binary) {
                return <div className={classes.center}>{label}</div>;
              }

              return null;
            }),
            i18n(
              'report.orderExecutionSummary.initialDeposit',
              translationsConfig
            ),
            i18n(
              'report.orderExecutionSummary.monthlySaving',
              translationsConfig
            )
          ]}
          data={tableElements}
          dataKeys={[
            'fund',
            'isin',
            ...productAttributes.map(({ name }) => name),
            'initialDeposit',
            'monthlySaving'
          ]}
          highlightLastRow
        />
      </div>

      {advisorNotes && (
        <AdvisorNotesSection
          text={advisorNotes}
          className={classes.advisorNotes}
        />
      )}
    </Section>
  );
};

export default OrderExecutionSummarySection;
